body {
  margin: 0;
  font-family: "Josefin Sans",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.search {
  background-color: #45585D;
  overflow-y: scroll;
}

.icon {
  margin-left: 10px;
  margin-right: 10px;
  width: 50px;
  height: 40px;
  border-radius: 10%;
  border-radius: 5px;  
}

.modal-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  min-height: 90vh;
  background-color: black;
  text-align: center;
  display: block;
  overflow: scroll;
}

.modal-box-align {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  min-height: 90vh;
  background-color: black;
  text-align: justify;
  display: block;
  overflow: scroll;
}

.map-container {
  margin-top: 30px;
  margin-left: 30px;
  height: 50%;
  width: 50%;
}

.loader-container {
  width: 100%;
  height: 100vh;
  position: fixed;
  background-image: url("./img/walking.gif") ;
  background-color: rgba(252, 252, 252, 0.834);
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
}

.video-thumbnail {
  height: 40vh;
  width: 25vw;
  object-fit: cover;
}

.video-full {
  height: 55vh;
  width: 50vw;
  object-fit: cover;
}

@media only screen and (min-width: 75px) {
  .paypal {
    min-width: 75px;
    max-width: 150px;
  }
}

@media only screen and (min-width: 150px) {
  .paypal {
    min-width: 150px;
    max-width: 200px;
  }
}

@media only screen and (min-width: 200px) {
  .paypal {
    min-width: 200px;
    max-width: 300px;
  }
}

@media only screen and (min-width: 300px) {
  .paypal {
    min-width: 300px;
    max-width: 500px;
  }
}

@media only screen and (min-width: 500px) {
  .paypal {
    min-width: 500px;
    max-width: 750px;
  }
}

#leva__root > * {
  right: 52% !important;
  top: 15vh !important;
  width: 200px !important;
}

.leva-c-kWgxhW {
  right: 44%!important;
  top: 18vh!important;
}

.MuiImageListItemBar-titleWrap {
  overflow: auto !important;
}